import * as React from 'react';
import { IScreenProps, IScreenState } from '../interfaces';
import './Thanks.css';
import { ScreenList } from '../definitions';

require('dotenv').config();

const { REACT_APP_IN_PRODUCTIONMODE } = process.env;


interface IReceiptCopy {
    emailID: string,
    errorEmail: string,
    isSendClicked: boolean,
    isalertColor: string,
    alertMessage: string,
    enable: boolean
}

export class Thanks extends React.Component<IScreenProps, IReceiptCopy> {

    constructor(props: IScreenProps) {
        super(props)

        this.state = {
            emailID: "",
            errorEmail: "onvalidEmail",
            isSendClicked: false,
            isalertColor: "",
            alertMessage: "",
            enable: true
        }
    }

    render() {
        const RedirectToHomePage = async () => {

            var uri = window.location.toString();
            if (uri.indexOf("?") > 0) {
                var clean_uri = uri.substring(0, uri.indexOf("?"));
                window.history.replaceState({}, document.title, clean_uri);
            }

            if (process.env.NODE_ENV == "production") {
                window.location.href = `${REACT_APP_IN_PRODUCTIONMODE}`
            }
            else {
                this.props.changeState({
                    screen: ScreenList.Home
                });
            }
        }

        const handleChange = async (event: any) => {

            event.preventDefault();

            this.setState({
                emailID: event.target.value
            });

            if (this.state.isSendClicked) {
                if (isEmail(this.state.emailID)) {
                    this.setState({
                        errorEmail: "onvalidEmail",
                        isSendClicked: true,
                        isalertColor: "",
                        alertMessage: ""
                    });
                }
                else {
                    this.setState({
                        errorEmail: "oninvalidEmail",
                        isSendClicked: true,
                        isalertColor: "receiptdanger",
                        alertMessage: "Please enter valid email id."
                    });
                }
            }
        };

        const handleSubmit = async (event: any) => {

            // Email Id validation on client side
            if (isEmpty(this.state.emailID)) {
                // Error
                this.setState({
                    errorEmail: "oninvalidEmail",
                    isSendClicked: true,
                    isalertColor: "receiptdanger",
                    alertMessage: "Please enter your email id."
                });
            }
            else if (isEmail(this.state.emailID)) {
                this.setState({
                    enable: false
                });

                var emailstatus = "";
                var alertMessage = "";

                await fetch('/api/Receipt/ReceiptCopy/', {
                    body: new Blob([JSON.stringify({
                        EmailId: this.state.emailID,
                        TransactionId: this.props.state.store.transactionPaymentId
                    })], {
                        type: 'application/json'
                    }),
                    method: 'POST',
                }).then(function (response) {

                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    // Do stuff with the response
                    return response.json();
                })
                    .then(function (myJson) {
                        emailstatus = myJson.status;
                        alertMessage = myJson.message;
                    })
                    .catch(function (error) {
                        console.log('Looks like there was a problem: \n', error);
                    });

                if (emailstatus) {
                    this.setState({
                        errorEmail: "onvalidEmail",
                        isSendClicked: true,
                        isalertColor: "receiptsuccess",
                        alertMessage: alertMessage,
                        emailID: "",
                        enable: true
                    }, () => {
                        window.setTimeout(() => {
                            // Redirect to Home page
                            RedirectToHomePage();
                        }, 3000)
                    });
                }
                else {
                    this.setState({
                        errorEmail: "oninvalidEmail",
                        isSendClicked: true,
                        isalertColor: "receiptdanger",
                        alertMessage: alertMessage,
                        enable: true
                    });
                }

            }
            else {
                // Error
                this.setState({
                    errorEmail: "oninvalidEmail",
                    isSendClicked: true,
                    isalertColor: "receiptdanger",
                    alertMessage: "Please enter valid email id."
                });
            }
        };

        return (
            <div>
                <div className="thankyouForm" style={{
                    width: "100vw",
                    height: "100vh",
                }}>
                    <div className="thanksForm">
                        { /*
                         <p>
                            thank you, enjoy!
                        </p>
                        <p>
                            For another drink scan the QR code again.
                        </p>

                         <button className="btn btn-small btn-dark"
                            onClick={ () => window.close() }>
                            done
                         </button>
                     */}

                        <h2 className="thankyou">Thank You</h2>

                        <br />

                        <div className="container tabelLabel">
                            <h6>would you like a receipt?</h6>
                            <input type="email" className={`form-control ${this.state.errorEmail}`} id="emailID" name="emailID" aria-describedby="emailHelp" placeholder="Email address"
                                value={this.state.emailID} onChange={handleChange}
                            />

                            <br />

                            {
                                this.state.alertMessage != "" ?
                                    <div>
                                        <span className={this.state.isalertColor}>{this.state.alertMessage}</span>
                                        <br />
                                        <br />
                                    </div>
                                    : ""
                            }

                            <div className="Thanks">
                                <button type="submit" className="btn btn-dark btn-lg btn-block" disabled={!this.state.enable}
                                    onClick={handleSubmit}>{this.state.enable ? "Send" : "Sending..."}</button>
                            </div>
                            <br />
                            <br />

                            <div className="Thanks">
                                <button type="button" className="btn btn-light btn-lg btn-block"
                                    onClick={RedirectToHomePage}>No Thanks</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export const isEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const isEmpty = (thing: any) => {
    let empty = false;

    switch (typeof thing) {
        case 'undefined':
            empty = true;
            break;
        case 'string':
            if (thing.trim().length === 0) {
                empty = true;
            }
            break;
        case 'object':
            if (thing === null) {
                empty = true;
            } else if (Object.keys(thing).length === 0) {
                empty = true;
            }
            break;
        default:
            empty = true;
    }

    return empty;
}
