import * as React from 'react'
import { DimensionsText } from '../definitions'
import './Common.css'

interface IOrderFooterProps {
    changeState: Function
    progressImage: string
    buttonActive: boolean
    nextDestination: number
    backDestination: number
    nextText: string
    nextFunction: Function
    backFunction: Function
    pageType: string
}

//<style>{{
//    .orderFooter {
//        position: fixed;
//        width: 100vw;
//        bottom: 0px;
//    }
//}}
    
//</style>

export class OrderFooter extends React.Component<Partial<IOrderFooterProps>, {
}> {
    render () {

        let {
            progressImage,
            buttonActive,
            nextDestination,
            backDestination,
            nextText = "next",
            nextFunction,
            backFunction,
            pageType
        } = this.props

        return (
            <div>
                <div className="orderFooter">

                    <div className="footerBtn" style={ {
                        margin: DimensionsText.MasterOffset,
                        textAlign: "center"
                    } }>
                        <div style={ {
                            display: "inline-block",
                            paddingRight: "3vw"
                        } }>
                            <button className="btn btn-small btn-light" onClick={ () => {
                                this.props.changeState( { screen: backDestination } )
                                if ( backFunction )
                                    backFunction()
                            } }>
                                back
                            </button>
                        </div>

                        {
                            (pageType === "size" || pageType === "boost") ? "" :
                            <div style={{ display: "inline-block" }}>
                                <button className="btn btn-small btn-dark" disabled={!buttonActive}
                                    onClick={() => {
                                        this.props.changeState({ screen: nextDestination })
                                        if (nextFunction)
                                            nextFunction()
                                    }}>
                                    {nextText}
                                </button>
                            </div>
                        }
                    </div>
                    <div className="footerProgess" style={ {
                        margin: DimensionsText.MasterOffset,
                        textAlign: "center"
                    } }>
                        <div >
                            <img src={ progressImage } alt="progress bar" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}