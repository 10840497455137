import * as React from 'react'
import { IScreenProps } from '../interfaces'
import { ScreenList } from '../definitions'

require('dotenv').config();
const { REACT_APP_IN_PRODUCTIONMODE } = process.env;

interface ICancelProps extends Partial<IScreenProps> {
    backFunction: Function,
    transactionId: string
}

export class Cancel extends React.Component<ICancelProps> {
    render() {

        let { backFunction, transactionId } = this.props

        //const reloadHomePage = (shouldOpenNewTab: boolean = true) => {
        //    let domain = (new URL(window.location.href));
        //    shouldOpenNewTab ? window.open(domain.host, "_blank") : window.location.href = domain.hash;
        //}

        return (
            <div className="" style={{
                position: "absolute",
                transform: "translate(-50%, -50%)",
                top: "50%",
                textAlign: "center",
                left: "50%"
            }}>
                <p style={{
                    textAlign: "center",
                    width: "60vw",
                    maxWidth: "300px",
                    lineHeight: "min(4vw, 20px)",
                    fontFamily: "Gotham-Light",
                    fontSize: "min(4vw, 20px)",
                    marginBottom: "60px"
                }}>
                    are you sure you want to cancel your order?
                </p>
                <div className="cancelbtn">
                <button className="btn btn-dark btn-wide" style={{
                    display: "inline-block",
                    marginRight: "10px"

                }}
                    onClick={() => {

                        if (transactionId) {
                            fetch('/endpoint/request/cancelorder/', {
                                body: new Blob([JSON.stringify({
                                    transactionId: transactionId
                                })], {
                                    type: 'application/json'
                                }),
                                method: 'POST',
                            })
                        }

                        var uri = window.location.toString();
                        if (uri.indexOf("?") > 0) {
                            var clean_uri = uri.substring(0, uri.indexOf("?"));
                            window.history.replaceState({}, document.title, clean_uri);
                        }

                        //backFunction();
                        if (process.env.NODE_ENV == "production") {
                            window.location.href = `${REACT_APP_IN_PRODUCTIONMODE}`
                        }
                        else {
                            this.props.changeState({
                                screen: ScreenList.Home,
                                size: null,
                                boost: null,
                                expandedBoost: null,
                                selectedPayment: -1,

                                clientSecret: null,
                                error: null,
                                metadata: null,
                                processing: false,
                                succeeded: false
                            });
                        }                        
                    }

                    }>
                    cancel order
                </button>
                <button className="btn btn-light btn-wide" style={{
                    display: "inline-block",
                }} onClick={() => backFunction()}>
                    go back
                </button>
            </div>
            </div>
        )
    }
}