import * as React from 'react'
import {
    CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    PaymentRequestButtonElement,
    useStripe,
    useElements,
    ElementsConsumer,
  
   
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js';

import { CardSection } from './CardSection';

import PaypalButton from 'react-paypal-express-checkout'

import { OrderHeader } from './OrderHeader'
import { OrderBody } from './OrderBody'
import { OrderFooter } from './OrderFooter'
import { Cancel } from './Cancel'

import progress from '../assets/Progress-Bar-Review.png'
import codes from './Codes'
import api from '../api'
import { IScreenProps, IScreenState } from '../interfaces'
import { ScreenList } from '../definitions'
import { debug, exception } from 'console';
import ReactDOM from 'react-dom';
import { Spinner } from 'reactstrap';
import { PayPalButton } from 'react-paypal-button-v2';
import { ContentLoader } from '../components/stripeElements/ContentLoader'

import './CardSectionStyles.css';
//import { isError } from 'util';

        require('dotenv').config();

interface IPayDetailsState extends IScreenState {
    clickedNext: boolean
    paymentRequest: any
    canMakePayment: boolean
    machine: string
    loading: boolean
    isCardValid: boolean
    success: boolean
    text: string
    disabled: boolean
    paybuttontext: string
}

//const stripe = useStripe();
//const elements = useElements();
export class PayDetails extends React.Component<IScreenProps & { type: any }, IPayDetailsState> {

    onDispenseFaild: () => void

    constructor ( props: IScreenProps & { type: any } ) {

        super( props )

        this.state = {
            clickedNext: false,
            cancelling: false,
            paymentRequest: null,
            canMakePayment: false,
            machine: "",
            loading: true,
            isCardValid: true,
            success: false,
            text: '',
            disabled: false,
            paybuttontext:"",
        }                
    }
   
    renderPaypal() {
        const { REACT_APP_CURRENCY, REACT_APP_COUNTRY } = process.env;
        const { stripe, elements } = this.props
        let {
            clientSecret,
            error,
            metadata,
            processing,
            succeeded,
            selectedSize,
            selectedBoost 
        } = this.props.state
        
        let size = this.props.state.size
        let boost = this.props.state.boost
        let payment = this.props.state.selectedPayment

        if (this.props.state.screen === ScreenList.PayDetails && payment === 2 && !this.state.canMakePayment && !this.state.paymentRequest) {
           
            const pr = stripe.paymentRequest({
                //country: 'CA',
                country: REACT_APP_COUNTRY,
                //currency: 'cad',
                currency: REACT_APP_CURRENCY.toLowerCase(),
                total: {
                    label: 'DrinkUp',
                    amount: (selectedSize.price +selectedBoost.price),
                    
                },
                requestPayerName: true,
                requestPayerEmail: true,
                requestPayerPhone: true,
            })

            pr.on('paymentmethod', async (ev) => {

                const stripePayment = {
                    Address: {
                        line1: ev.paymentMethod.billing_details.address.line1,
                        postal_code: ev.paymentMethod.billing_details.address.postal_code,
                        city: ev.paymentMethod.billing_details.address.city,
                        state: ev.paymentMethod.billing_details.address.state,
                        country_code: ev.paymentMethod.billing_details.address.country,
                    },

                    Name: ev.payerName,
                    Email: ev.payerEmail,
                    Amount: (selectedSize.price + selectedBoost.price),
                    Description: "DrinkUp Payment",
                    //Currency: "cad",
                    Currency: REACT_APP_CURRENCY.toLowerCase(),
                    Source: "",
                  };

                api.createPaymentIntent({
                        stripePayment
                    })
                    .then(async (clientSecret) => {
                        const confirmResult = await stripe.confirmCardPayment(
                            clientSecret,
                            { payment_method: ev.paymentMethod.id },
                            { handleActions: false }
                        );

                        if (confirmResult.error) {
                            ev.complete('fail');
                        }
                        else {
                            ev.complete('success');
                            //console.log("*** confirmResult ****", confirmResult);
                            //added by me
                            if (confirmResult.paymentIntent.status == 'requires_action') {
                                //requires_action will present some model and might redirect to the banks  page to collect one time password etc.
                                stripe.confirmCardPayment(clientSecret);
                            }
                           
                            // Api calling for save
                            const mobilePay = {
                                Amount: confirmResult.paymentIntent.amount/100,
                                PaymentMethod: confirmResult.paymentIntent.payment_method,
                                PaymentID: confirmResult.paymentIntent.id,
                                PaymentType: "Mobile",
                                Status: confirmResult.paymentIntent.status,
                                Boost_Id: this.props.state.selectedBoost.id,
                                Size_Id: this.props.state.selectedSize.id,
                                //MachineId: this.props.state.machine,
                                MachineId: this.props.state.machine ? this.props.state.machine : selectedSize.machine_Id,
                                Total: (confirmResult.paymentIntent.amount/100)
                            }

                            //var details = api.googleApplePayment({
                            //    mobilePay
                            //});

                            const paymentResult = await fetch('api/Stripe/MobilePayment/', {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify(mobilePay)
                            }).then(function (response) {
                                return response.json();
                            });

                            if (paymentResult.success) {
                                this.setState({
                                    loading: false
                                });

                                this.props.changeState({
                                    clickedNext: true,
                                    succeeded: true,
                                    screen: ScreenList.Dispense,
                                });

                                this.props.state.store.transactionPaymentId = paymentResult.paymentId;
                            }
                            else {
                                this.props.changeState({
                                    clickedNext: true,
                                    succeeded: true,
                                    screen: ScreenList.DispenseFailed
                                })
                            }
                        }
                    })
                    .catch((err) => {
                        //console.log(err);
                    });
            });

            //console.log(pr.canMakePayment())
            pr.canMakePayment().then((result) => {
                if (result) console.log('true, you can make a payment now')
                this.setState({ canMakePayment: !!result })
            })

            this.setState({ paymentRequest: pr })
        }

        const PayPalonSuccess = async (payment: any) => 
        {
            this.setState({
                loading: true
            });

            // ****************** Store PayPal responce in sql database ********************     

            const TransactionViewModel = {
                Address: payment.address,
                cancelled: payment.cancelled,
                email: payment.email,
                paid: payment.paid,
                payerID: payment.payerID,
                paymentID: payment.paymentID,
                paymentToken: payment.paymentToken,
                Total: selectedBoost.price / 100 + selectedSize.price / 100, 
                Boost_Id: this.props.state.selectedBoost.id,
                Size_Id: this.props.state.selectedSize.id,
                //MachineId: this.props.state.machine
                MachineId: this.props.state.machine ? this.props.state.machine : selectedSize.machine_Id
            };

            var data = JSON.stringify(TransactionViewModel);

             const confirmResult = await fetch('api/Paypal/Payment/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: data
            }).then(function (response) {
                return response.json();
            });

            //console.log("***************confirmResult ", confirmResult);
            if (confirmResult.success) {
                this.setState({
                    loading: false
                });

                this.props.changeState({
                    clickedNext: true,
                    succeeded: true,
                    screen: ScreenList.Dispense,
                });
 
                this.props.state.store.transactionPaymentId = confirmResult.paymentId; 
            }
            else {
                this.props.changeState({
                    clickedNext: true,
                    succeeded: true,
                    screen: ScreenList.DispenseFailed
                })
            }
        }

        const PayPalonCancel = (data: any) => {
            // User pressed "cancel" or close Paypal's popup!
            console.log('The payment was cancelled!', data);
        }

        const PayPalonError = (err: any) => {
            // The main Paypal's script cannot be loaded or somethings block the loading of that script!
            console.log("Error!", err);
        }

        let PayPalenv = 'production'; // you can set here to 'production' for production
        //let PayPalcurrency = 'CAD'; // or you can set this value from your props or state
        let PayPalcurrency = REACT_APP_CURRENCY; // or you can set this value from your props or state
        let PayPalStyle = {  // This is for paypal button style 
            shape: 'rect',
            label: 'paypal',
            tagline: 'false',
            size: 'responsive',
        };
        const { REACT_APP_PAYPAL_SANDBOX, REACT_APP_PAYPAL_PRODUCTION } = process.env;
        
        const PayPalclient = {
            sandbox: `${REACT_APP_PAYPAL_SANDBOX}`,
            production: `${REACT_APP_PAYPAL_PRODUCTION}`,
        }

        const handleSubmit = async (event: any) => {
            // We don't want to let default form submission happen here, 
            // which would refresh the page.
            event.preventDefault();

            const { stripe, elements } = this.props

            if (!stripe || !elements) {
                return;
            }

            if (this.state.disabled) {
                return;
            }
            this.setState({ disabled: true });

            //const card = elements.getElement(CardElement);
            const cardNumber = elements.getElement(CardNumberElement);

            const result = await stripe.createToken(cardNumber);
            if (result.error) {
                this.setState({ text: result.error.message, isCardValid: false, disabled: false })
            }

            if (result && result.token) {
                try {

                    const mPayIntent = {
                        amount: (selectedSize.price + selectedBoost.price),
                        //machine_id: this.props.state.machine, // Example : "704ead6e-5f81-4f29-9b88-783613133f72",
                        machine_id: this.props.state.machine ? this.props.state.machine : selectedSize.machine_Id,
                        queryCode: ""
                    };

                    var datamPayIntent = JSON.stringify(mPayIntent);
                    
                    const resPayIntent = await fetch("api/Stripe/mStripeIntent/", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: datamPayIntent
                    });

                    const responsemPayIntent = await resPayIntent.json();

                    if (responsemPayIntent && responsemPayIntent.client_secret) {
                        const cardElement = elements.getElement(CardNumberElement);
                        const confirmPayment = await stripe.confirmCardPayment(
                            responsemPayIntent.client_secret,
                            { payment_method: { card: cardElement } }
                        );

                        //console.log(confirmPayment);
                        const { paymentIntent } = confirmPayment;
                        if (paymentIntent && paymentIntent.status === "succeeded") {
                            
                            // Passing param in payment api ***** TODO
                            const stripePayment = {
                                Name: "DrinkUp Customer",
                                Email: "",
                                Amount: (selectedSize.price / 100 + selectedBoost.price / 100),
                                Description: "DrinkUp Payment",
                                Currency: REACT_APP_CURRENCY.toLowerCase(),
                                //Source: result.token.id, // Token ID,
                                Boost_Id: this.props.state.selectedBoost.id,
                                Size_Id: this.props.state.selectedSize.id,
                                //MachineId: this.props.state.machine,
                                MachineId: this.props.state.machine ? this.props.state.machine : selectedSize.machine_Id,
                                Total: (selectedSize.price / 100 + selectedBoost.price / 100),
                                PaymentMethod: paymentIntent.payment_method,
                                PaymentIntent: paymentIntent.id
                            };

                            // API calling for payment in stripe & save response in sql database.
                            var data = JSON.stringify(stripePayment);

                            const confirmResult = await fetch('api/Stripe/SavePaymnetInfo/', {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: data
                            });

                            const blocks = await confirmResult.json();

                            // To check the api response status 
                            if (!blocks.status) {
                                this.props.changeState({
                                    clickedNext: true,
                                    succeeded: true,
                                    screen: ScreenList.DispenseFailed
                                    //screen: ScreenList.Dispense
                                });
                            }
                            else if (blocks.status) {
                                this.props.changeState({
                                    clickedNext: true,
                                    succeeded: true,
                                    screen: ScreenList.Dispense,
                                });

                                this.props.state.store.transactionPaymentId = blocks.paymentId;

                                this.setState({ text: "", isCardValid: true })
                            }
                            else {
                                this.onDispenseFaild();
                            }
                        }
                        else {
                            this.props.changeState({
                                clickedNext: true,
                                succeeded: true,
                                screen: ScreenList.DispenseFailed
                            });

                            this.onDispenseFaild();
                        }
                    }
                    else {                        
                        this.props.changeState({
                            clickedNext: true,
                            succeeded: true,
                            screen: ScreenList.DispenseFailed
                        });

                        this.onDispenseFaild();
                    }
                }
                catch (error) {
                    // handle error instead of printing on console
                    //this.setState({ paybuttontext: "" })

                    this.props.changeState({
                        clickedNext: false,
                        succeeded: false,
                        screen: ScreenList.PayDetails,

                    });
                }
            }
        }; 

        this.onDispenseFaild = async () => {
            if (this.props.state.store.transactionId) {
                fetch('/endpoint/request/cancelorder/', {
                    body: new Blob([JSON.stringify({
                        transactionId: this.props.state.store.transactionId
                    })], {
                        type: 'application/json'
                    }),
                    method: 'POST',
                });
            };
        }

        return (


            <div className="Container">
              
                {this.state.cancelling ? 
                    <Cancel
                        changeState={this.props.changeState}
                        backFunction={() => this.setState({ cancelling: false })} transactionId={this.props.state.store.transactionId}
                    />
                    :
                <div className="Container">
                    <OrderHeader
                        changeState={this.props.changeState}
                                title="Enter your card info"
                                transactionId={this.props.state.store.transactionId}
                                started_time={this.props.state.store.started_time}
                        closeFunction={() => this.setState({ cancelling: true })}
                    />
                    <OrderBody height="calc(calc(var(--vh, 1vh) * 100) - 214px)">

                    <div className="Container">
                                
                    
                    {
                        payment === -1 ? null :
                        payment  === 0 ?

                            <div className="Container">

                                                <span style={{
                                                    fontFamily: 'Gotham-Light',
                                                    fontSize: 'min(5vw, 50px)',
                                                }}>
                                                    Enter your card info
                                                </span>
                                                <br />
                                                <br />
                                                <br />


                                                {size === null || boost === null ? 
                                                    <div>
                                                        {/*<span>loading payment gateway</span>*/}
                                                        <div className="loader">

                                                        </div>
                                                    </div> :
                                                    
                                    <div style={{ width: "100%", height: "100%" }}>
                                                        
                                                        <PaypalButton async
                                                            style={PayPalStyle}
                                                            env={PayPalenv}
                                                            client={PayPalclient}
                                                            currency={PayPalcurrency}
                                                            total={(selectedSize.price / 100 + selectedBoost.price / 100).toFixed(2)}
                                                            onError={PayPalonError}
                                                            onSuccess={PayPalonSuccess}
                                                            onCancel={PayPalonCancel}
                                                            
                                                            
                                                            />

                                    </div>
                                }
                            </div>
                        :
                                            payment === 1 ?
                                                
                                                     
                                                <div>
                                                    {
                                                        size == null || boost == null ?
                                                            <div>
                                                                {/*<span>loading payment gateway</span>*/}
                                                                <div className="loader">

                                                                </div>
                                                            </div> :
                                                            <div className="container">

                                                                <div id="Checkout" className="inline">

                                                                    <form onSubmit={handleSubmit}>
                                                                        <div className="form-group">
                                                                            <label className="totalAmount">Payment amount</label>
                                                                            <div className="amount-placeholder">
                                                                                <span>${(selectedSize.price / 100 + selectedBoost.price / 100).toFixed(2)}</span>
                                                                            </div>
                                                                        </div>

                                                                        <CardSection />
                                                                        {/*
                                                                    <button id="PayButton" className="btn btn-block btn-dark submit-button" disabled={this.state.disabled}>
                                                                        <span className="submit-button-lock"></span>
                                                                        <span className="align-middle">{this.state.disabled ? ' Please Wait' : ` Pay ${(selectedSize.price / 100 + selectedBoost.price / 100).toFixed(2)}`}</span>
                                                                    </button>*/}

                                                                    </form>


                                                                </div>
                                                                <div className="card-row">
                                                                    <span className="visa"></span>
                                                                    <span className="mastercard"></span>
                                                                    <span className="amex"></span>
                                                                    <span className="discover"></span>
                                                                </div>
                                                                {/*
                                                            <div>
                                                                 <CardElement /> 
                                                                <CardNumberElement />
                                                                <CardExpiryElement />
                                                                <CardCvcElement />
                                                                <button onClick={handlePay}>Pay</button>
                                                            </div>
                                                           */}

                                                            </div>
                                                     }
                                                    

                                                    
                                                    

                            </div>
                        :
                            <div>


                                <span style={{
                                    fontFamily: 'Gotham-Light',
                                    fontSize: 'min(5vw, 50px)',
                                    }}>
                                       Enter your card info
                                </span>
                                                    <br />
                                                    <br />
                                                    <br />

                                {this.state.canMakePayment ? (
                            
                            <PaymentRequestButtonElement
                                className="PaymentRequestButton"

                                options={{
                                    paymentRequest: this.state.paymentRequest,
                                    style: {
                                        paymentRequestButton: {
                                            type: 'default',
                                            theme: 'dark',
                                            height: '35px'
                                        }
                                    }
                                                            }}
                                                            

                          />
                        ) : (
                                                            //<span>can't make payment</span>
                                <div>
                                  
                                    <div className="loader">
                                        
                                     </div>
                                    <span style={{ display: "flex", justifyContent:"center" }}>loading payment gateway</span>
                                </div>
                                                            
                        )}
                        {error && <div>{error}</div>}
                            </div>
                        }    
                    </div>
                        </OrderBody> 
                    </div>
                }
                {
                    this.state.cancelling ?
                        <div>
                            <Cancel
                                changeState={this.props.changeState}
                                backFunction={() => this.setState({ cancelling: false })} transactionId={this.props.state.store.transactionId}
                            />
                        </div>
                        :
                        payment === -1 ? null :
                            payment === 1 ?
                                <div>


                                    <div>
                                        <div className="orderFooterCard" style={{
                                            position: "fixed",
                                            width: "100vw",
                                            bottom: "90px"
                                        }}>

                                            <div style={{
                                                textAlign: "center"
                                            }}>
                                                <div style={{
                                                    display: "inline-block",
                                                    paddingRight: "3vw"
                                                }}>
                                                    <button className="btn btn-small btn-light"
                                                        onClick={() => this.props.changeState({ screen: ScreenList.Pay })}>
                                                        back
                                                </button>
                                                </div>

                                                <div style={{ display: "inline-block" }}>
                                                    <button className="btn btn-small btn-dark" onClick={handleSubmit}>
                                                        {/*{this.state.disabled ? `${this.state.paybuttontext}` : ` Pay ${(selectedSize.price / 100 + selectedBoost.price / 100).toFixed(2)}`}*/}
                                                        {this.state.disabled ? "Please Wait" : ` Pay ${(selectedSize.price / 100 + selectedBoost.price / 100).toFixed(2)}`}

                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div>
                                        {
                                            !this.state.isCardValid ?
                                                <div className="CardDetailsError">
                                                    {this.state.text}
                                                </div> : null
                                        }
                                    </div>
                                </div>

                                :
                                <OrderFooter
                                    changeState={this.props.changeState}
                                    buttonActive={false}
                                    nextDestination={6}
                                    backDestination={4}
                                    progressImage={progress}
                                    pageType={"boost"}
                                    nextFunction={() => { }}
                                />
                            
                }
                
            </div>
        );
    }
    
    sleep = (milliseconds: number) => {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    };

    wait = async (milliseconds = 10000) => {
        await this.sleep(milliseconds);
        this.setState({
            loading: false
        });
    };

    render() {
        const styles = {
            spinnerStyle: {
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '50%',
                marginTop:'0%'
            }
        }
   
        let contents = this.state.loading
            ? <div>
                <OrderHeader
                    changeState={this.props.changeState}
                    title="review & pay"
                    transactionId={this.props.state.store.transactionId}
                    started_time={this.props.state.store.started_time}
                    closeFunction={() => this.setState({ cancelling: true })}
                />
               
                {/*<ContentLoader />*/}
                <div>
                    {/*<span>loading payment gateway</span>*/}
                    <div className="loader">

                    </div>
                </div>
                <OrderFooter
                    changeState={this.props.changeState}
                    buttonActive={false}
                    nextDestination={6}
                    backDestination={4}
                    progressImage={progress}
                    nextFunction={() => { }} />
                </div>
            : this.renderPaypal();
        return (
           
            <div className="Container">

                {contents}
            </div>
        );
    }
    
    componentDidMount() {
        this.wait();
    }
    
}



