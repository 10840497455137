import * as React from 'react'

export class SessionExpired extends React.Component {
    render () {
        return (
            <div style={ {
                width: "100vw",
                height: "100vh",
            } }>
                <span style={ {
                    width: "100%",
                    textAlign: "center",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    fontFamily: "Gotham-Medium",
                    fontSize: "5vw"
                } }>
                    <p>
                        session timeout, please try again
                    </p>
                </span>
            </div>
        )
    }
}