import * as React from 'react'
import { IScreenProps } from '../interfaces'

export class DispenseFailed extends React.Component<IScreenProps> {
    render() {
        return (
            <div style={{
                width: "100vw",
                height: "100vh",
            }}>
                <span style={{
                    width: "100%",
                    textAlign: "center",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    fontFamily: "Gotham-Medium",
                    fontSize: "5vw"
                }}>
                    Your Transaction Cannot be completed...!
                    <br />
                    Please try again
                </span>
            </div>
        )
    }
}