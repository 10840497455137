import * as React from 'react'
import { CardElement, CardExpiryElement, CardNumberElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './CardSectionStyles.css';
import { loadStripe } from '@stripe/stripe-js';
import { CardCvcElements } from '../components/stripeElements/CardCvcElements'
import { CardExpiryElements } from '../components/stripeElements/CardExpiryElements'
import { CardNumberElements } from '../components/stripeElements/CardNumberElements'


// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontWeight: '500',
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
            ':-webkit-autofill': {
                color: '#fce883',
            },
            display: "flex",
            flexDirection:"column",
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
        
    },
};


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.


export class CardSection extends React.Component {

    render() {

        return (
            <fieldset>
                <div>
                    <div className="formcontrolchanges">
                        
                        <CardNumberElements/>
                        <CardExpiryElements/>
                        <CardCvcElements/>
                        
                        
                    </div>
                </div>


            </fieldset>


        )
    }

    

    
}




