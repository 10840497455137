import { IBoostDescriptor, ISizeDescriptor } from '../interfaces'

let codes: {
    sizes: Array<ISizeDescriptor>,
    boosts: Array<IBoostDescriptor>,
    payment: any,
    started_time: Date,
    machine: string,
    transactionPaymentId: string
} = {
    sizes: [],
    boosts: [],
    payment: [
        {
            name: "Paypal"
        },
        {
            name: "Credit Card"
        },
        {
            name: "Mobile"
        }
    ],
    started_time: new Date(),
    machine: "66c21e6a-efc1-41d7-ac86-a5b32707213b",
    transactionPaymentId: ''
}

//fetch('/api/sizes/GetSizes')
//    .then(data => data.json()).then((json: Array<ISizeDescriptor>) => {
//        //console.warn(json);
//        codes.sizes = json;
//    });

//fetch('/api/Boosts/Getboosts')
//    .then(data => data.json()).then((json: Array<IBoostDescriptor>) => {
//        //console.warn(json);
//       codes.boosts = json;
//   });

export default codes