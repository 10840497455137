import { debug } from 'console'
import * as React from 'react'

import close from '../assets/Close.png'
import { ScreenList } from '../definitions'
import { IAppState } from '../interfaces'

export class OrderHeader extends React.Component<{
    changeState: Function
    closeFunction: React.MouseEventHandler
    title: string
    transactionId: string,
    started_time: Date
}> {
    render() {
        let {
            changeState,
            closeFunction = () => { },
            title,
            transactionId,
            started_time
        } = this.props

        return (
            <div style={{ height: "64px" }}>
                <div style={{
                    maxHeight: "64px",
                    position: "fixed",
                    width: "100vw",
                    overflow: "hidden",
                    boxShadow: "0px 3px 5px 2px #E6E6E6"
                }}>
                    <div style={{
                        lineHeight: "50px",
                        verticalAlign: "middle"
                    }}>
                        <span style={{
                            marginLeft: "10vw",
                            fontFamily: "Gotham-Medium",
                            fontSize: "min(20px, 8vw)",
                            textTransform: "lowercase"
                        }}>
                            {title}
                        </span>

                        <div style={{
                            display: "inline-block",
                            height: "100%",
                            marginRight: "5vw",
                            position: "relative",
                            float: "right"
                        }}>
                            {transactionId != null ? <Timer started_time={started_time} transactionId={transactionId} changeState={this.props.changeState} /> : null}

                            <img src={close} alt="close" style={{
                                verticalAlign: "middle",
                                width: "8vw",
                                maxWidth: "20px",
                            }} onClick={closeFunction} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

interface ITimer {
    timerSec: string
}

interface startTime {
    started_time: Date,
    changeState: Function,
    transactionId: string
}

class Timer extends React.Component<startTime, ITimer> {

    startTimer: () => void
    SessionExpired: () => void
    callApi: () => void

    constructor(props: startTime) {
        super(props)

        this.state = {
            timerSec: ""
        }

        // Set the date we're counting down to    
        var isActive = true;
        const countdownDate = new Date(this.props.started_time).getTime();
        this.startTimer = () => {

            // Get today's date and time
            const now = new Date().getTime();

            // Find the distance between now and the count down date
            const distance = countdownDate - now;
                                  
            // Time calculations for seconds
            var diff = (distance) / 1000;
            diff /= 60;
            var min = diff.toString()[0]; //Math.abs(Math.round(diff));

            const secs = Math.floor((distance % (1000 * 60)) / 1000);

            console.log("************ " + ("0" + min + " : " + (secs < 10 ? "0" : "") + secs));

            if (distance > 0) {
                // stop our Timer
                this.setState({ timerSec: ("0" + min + " : " + (secs < 10 ? "0" : "") + secs) }); 
            }
            //else {
            //    if (isActive) {
            //        this.callApi();
            //        isActive = false;
            //    }
            //}
        }

        this.callApi = async () => {

            try {
                const res = await fetch(`/endpoint/request/availsession/${this.props.transactionId}`, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });

                const blocks = await res.json();

                if (blocks.status) {
                    var uri = window.location.toString();
                    if (uri.indexOf("?") > 0) {
                        var clean_uri = uri.substring(0, uri.indexOf("?"));
                        window.history.replaceState({}, document.title, clean_uri);

                        this.props.changeState({
                            screen: ScreenList.SessionExpired
                        });
                    }
                }

            } catch (e) {
                //console.log(e);
            }
        }
    }

    render() {

        //setInterval(this.startTimer, 1000);

        return (
            <div style={{
                display: "inline-block",
                height: "100%",
                marginRight: "5vw",
            }}>
                {this.state.timerSec}
            </div>
        );
    }
}